@use '../../utils/style.variables' as styles;

.contact {
  h2 {
    margin: 48px 0;
  }

  form {
    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 16px;

    .submit-button,
    .form-input {
      width: 80%;
      height: 40px;
      max-width: 500px;
      min-width: 100px;
      border-radius: 8px;
      border: 2px solid styles.$primaryColor;

      font-family: inherit;
    }

    .submit-button:hover, .form-input:hover {
      box-shadow: 3px 3px 3px styles.$secondaryHalfTransparentColor;
    }

    textarea {
      min-height: 100px;
      padding: 8px;
    }

    .submit-button {
      width: auto;
      height: auto;
      padding: 12px 32px;

      background-color: styles.$primaryHalfTransparentColor;
      cursor: pointer;
    }
  }

  .loading {
    margin: 12px;
  }

  .email-response {
    margin: 16px;

    &.ok {
      color: green;
    }

    &.error {
      color: red;
    }
  }
}
