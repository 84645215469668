@use '../../utils/style.variables' as styles;

.footer {
  text-align: center;
  padding: 12px 0;
  position: relative;
  top: styles.$menubarHeight;

  background-color: styles.$secondaryColor;
  color: white;
  font-size: 12px;

  div {
    padding: 4px 0;
  }

  .owner {
    font-size: 14px;
    font-weight: bold;
  }
}
