@use '../../../utils/style.variables' as styles;

.nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;

  background-color: styles.$backgroundColor;

  .menu-bar {
    width: 100%;
    margin: 0 auto;
    max-width: styles.$smallScreenSize;
    height: styles.$menubarHeight;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 16px;

    overflow: hidden;

    transition: all 0.2s linear;

    a,
    button {
      padding: 6px;
      text-decoration: none;
      color: inherit;
    }

    .logo {
      height: 60px;
      transition: all 0.2s linear;
    }

    &-small {
      height: calc(styles.$menubarHeight / 1.5);

      .logo {
        height: 45px;
      }
    }
  }

  .hamburger-menu {
    display: none;

    &-icon {
      margin-left: auto;
      margin-right: 1rem;
      display: none;

      border: none;
      background-color: styles.$primaryTransparentColor;
      border-radius: 4px;

      img {
        width: 24px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: styles.$smallScreenSize) {
    .hamburger-menu-icon {
      display: block;
    }

    .hamburger-menu {
      display: block;
    }
  }
}
