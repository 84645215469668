@use '../../utils/style.variables' as styles;

.main-container {
  img {
    width: 100%;
  }

  .container {
    padding-top: 32px;
    display: flex;
    align-items: center;
    gap: 32px;

    .text-container {
      width: 60%;
      padding: 0 24px;

      p {
        text-align: left;
      }
    }

    .img-container {
      margin: 0 auto;
      width: 40%;
      padding: 24px 24px;
      max-width: 400px;
    }
  }
}

@media only screen and (max-width: styles.$smallScreenSize) {
  .main-container {
    .container {
      display: block;

      .text-container {
        width: 100%;
      }
      .img-container {
        width: 100%;
      }
    }
  }
}
