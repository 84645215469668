@use '../../../utils/style.variables' as styles;

.main {
  z-index: 1;
  min-height: calc(100vh - styles.$menubarHeight - styles.$footerbarHeight);
  position: relative;
  top: styles.$menubarHeight;

  .back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 20;

    button {
      border: none;
      border-radius: 50%;
      background-color: transparent;
    }

    img {
      background-color: styles.$primaryHalfTransparentColor;
      border: 2px solid styles.$backgroundColor;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      color: styles.$backgroundColor;
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &:hover {
        animation: none;
        background: styles.$backgroundColor;
        color: styles.$primaryColor;
        border: 2px solid styles.$primaryColor;
      }
    }
  }
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
