@use '../../utils/style.variables' as styles;

.container {
  p, h1, h2, h3, h4, li {
    text-align: left;
  }

  .base-info-container {
    display: flex;
    align-items: center;
    gap: 16px;

    div {
      width: 60%;
      padding: 12px 12px 12px 0;

      a {
        color: inherit;
      }
    }

    img {
      width: 40%;
      height: 40%;
      padding: 12px 0 12px 12px;
      max-width: 700px;
    }

    @media only screen and (max-width: styles.$smallScreenSize) {
      display: block;

      div {
        width: 100%;
        padding: 0;
      }

      img {
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: center;
      }
    }
  }

  .section-separator {
    margin-bottom: 60px;
  }

  .image-example-container {
    display: flex;
    justify-content: center;
    gap: 64px;
    flex-wrap: wrap;

    img {
      max-width: 300px;
      max-height: 600px;
    }
  }
}
