@use '../../../utils/style.variables' as styles;

$separatorColor: #b3b3b3;

.menu-items {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
  white-space: nowrap;

  font-size: 15px;

  position: relative;

  .separator {
    width: 1px;
    height: calc(styles.$menubarHeight / 2);
    background-color: $separatorColor;
  }
}

.hamburger-menu-items {
  border: 1px $separatorColor;
  border-style: solid none;
  width: 100%;
  display: flex;
  flex-direction: column;

  font-size: 15px;

  position: relative;

  // the link-container in MenuItem
  div > div {
    width: fit-content;
  }

  a {
    padding: 6px;
    text-decoration: none;
    color: inherit;
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: $separatorColor;
  }
}

@media only screen and (max-width: styles.$smallScreenSize) {
  .menu-items {
    display: none;
  }
}
