@use '../../../utils/style.variables' as styles;

.container {
  margin: 0 auto;
  max-width: styles.$smallScreenSize;
  padding-bottom: 32px;

  p {
    font-size: 16px;
    line-height: 24px;
  }
  
  color: styles.$textColor;

  h1 {
    color: black;
  }

  @media only screen and (max-width: styles.$smallScreenSize) {
    padding: 0 20px 32px;
  }
}
