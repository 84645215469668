@use '../../../utils/style.variables' as styles;

.nav-link-container {
  position: relative;
  cursor: pointer;
  padding: 16px 0;

  .link-container {
    position: relative;

    .active,
    a:hover {
      color: styles.$primaryColor;

      &::after {
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        background: styles.$primaryColor;
        opacity: 1;
        top: auto;
        bottom: -16px;
      }
    }
  }
}
