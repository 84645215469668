$primaryColor: #1ba039;
$primaryHalfTransparentColor: hsla(134, 71%, 37%, 0.5);
$primaryTransparentColor: hsla(134, 71%, 37%, 0.1);
$secondaryColor: #232051;
$secondaryHalfTransparentColor: hsla(244, 43%, 22%, 0.5);
$backgroundColor: #ffffff;
$textColor: #626262;

$smallScreenSize: 1200px;

$menubarHeight: 90px;
$footerbarHeight: 90px;
